import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'

import { media } from '../../utils/media'


const CardContainer = styled(posed.figure({
  initial: {
    opacity: 0,
    scale: 0.9,
    filter: "grayscale(0%)",
    transition: {
      duration: 500
    }
  },
  enter: {
    opacity: 1,
    scale: 1,
    filter: "grayscale(100%)",
    transition: {
      duration: 2000
    }
  }
}))`
  display: inline-block;
  border-right: 1px solid ${props => props.theme.colors.lightGray};
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  position: relative;

  width: 33.33%;
  padding-top: 33.33%;

  ${media.tablet`
    width: 16.66%;
    padding-top: 7.88%;
  `}
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  :hover {
    > img {
      filter: none;
      transition: 1s;
    }
  }
`

export const BrandCard = props => {
  return (
    <CardContainer>
      <ImageContainer>
        {props.children}
      </ImageContainer>
    </CardContainer>
  )
}