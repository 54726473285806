import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../../utils/media'

import FadeCarousel from '../Carousel/FadeCarousel'

const CoverImgContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${media.tablet`
    flex: 1;
  `}
`
const UseCaseCardContainer = styled.section`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    flex-direction: ${props => props.reversed ? 'row' : 'row-reverse'};
    width: 100%;
  `}
`

const UseCaseCardTitle = styled.h3`
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;

  ${media.tablet`
    font-size: 2em;
    text-align: ${props => props.reversed ? `left` : `right`};
  `}
`;

const UseCaseCardBody = styled.div`
  padding: 3rem 0;
  color: #fff;

  ${media.tablet`
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
  `}
`;

const UseCaseCardImage = styled(Img)`
  width: 100%;
  max-height: 300px;
`

const UseCaseCardDescription = styled.div`
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.8em;
  color: ${props => props.theme.colors.secondary};

  ${media.tablet`
    text-align: ${props => props.reversed ? `left` : `right`};
  `}
`;


export const UseCaseCard = props => {
  
  const displayContent = (props) => {

    return (
      <React.Fragment>
          <UseCaseCardTitle reversed={props.reversed}>{props.name}</UseCaseCardTitle>
          <UseCaseCardDescription
            reversed={props.reversed}
            dangerouslySetInnerHTML={{
              __html: props.description.childMarkdownRemark.html
            }}
          />
      </React.Fragment>
    )
  }

  return (
    <UseCaseCardContainer reversed={props.reversed}>
      {
        props.gallery ?
        <div style={{ maxWidth: `500px`, width: `100%`}}>
            <FadeCarousel>
              {props.gallery.map((image, index) => {
                return (
                  <div key={index}>
                    <UseCaseCardImage
                      fluid={image.fluid}
                      alt={image.title}
                    />
                  </div>
                )
              })}
            </FadeCarousel>
        </div> :
          <CoverImgContainer>
            <UseCaseCardImage
              fluid={props.image.fluid}
              alt={props.image.title}
            />
          </CoverImgContainer>
      }
      <UseCaseCardBody>
        {displayContent(props)}
      </UseCaseCardBody>
    </UseCaseCardContainer>
  )
}

UseCaseCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    })
  }),
  image: PropTypes.object.isRequired,
  reversed: PropTypes.bool,
}

UseCaseCard.defaultProps = {
  description: '',
  reversed: false,
}

export const useCaseCardFragment = graphql`
  fragment UseCaseCardFragment on ContentfulUseCase {
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    coverPhoto {
      title
      fixed(height: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    gallery {
      title
      fixed(height: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export const useCaseFragment = graphql`
  fragment UseCaseFragment on ContentfulUseCase {
    title
    coverPhoto {
      title
      fixed(height: 200) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`