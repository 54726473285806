import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { LineBreak } from '../../components/StyledComponents'

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${props => props.theme.dropShadow};
  border-radius: 10px;
  padding: 2rem;
  min-height: 500px;
  height: 100%;
  background-color: #002559;
  color: white;
`
const CardImage = styled(Img)`
  height: 128px;
  width: 128px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.gray };
  margin-bottom: 2rem;

  &::before {
    content: '';
    background-image: ${props => props.theme.gradients.primary};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`
const CustomLineBreak = styled(LineBreak)`
  margin-bottom: 0.5rem;
`
const CardName = styled.h3`
  text-align: center;
  margin-bottom: 0.5rem;
  color: white;
`

const CardJobTitle = styled.h5`
/* Engineer, Defender o: */
  font-size: 1rem;
  color: ${props => props.theme.colors.gray};
  text-align: center;
  font-weight: 400;
  margin: 0.5rem 0 1rem;
`

const CardDescription = styled.p`
  text-align: center;
  line-height: 1.8;
`
export const TeamCard = props => {
  const { photo } = props.teamMember
  return (
    <CardContainer>
      <CardImage fluid={photo.fluid} alt={photo.title}/>
      <CardName>{props.teamMember.name}</CardName>
      <CustomLineBreak />
      <CardJobTitle>{props.teamMember.jobTitle}</CardJobTitle>
      <CardDescription>{props.teamMember.shortBiography.internal.content}</CardDescription>
    </CardContainer>
  )
}

export const teamCardFragment = graphql`
  fragment TeamCardFragment on ContentfulTeamMember {
    name
    jobTitle
    shortBiography {
      internal {
        content
      }
    }
    photo {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
      title
    }
  }
`