import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { media } from '../../utils/media'

import { LineBreak, CoverImg } from '../../components/StyledComponents'

const CoverImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${media.tablet`
    flex: 1;
  `}
`
const HorizontalCardContainer = styled.section`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  ${media.tablet`
    flex-direction: ${props => (props.reversed ? 'row' : 'row-reverse')};
    width: 100%;
  `}
`

const HorizontalCardTitle = styled.h3`
  font-size: 2em;
  text-align: center;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.base};

  ${media.tablet`
    text-align: ${props => (props.reversed ? `left` : `right`)};
  `}
`

const HorizontalCardBody = styled.div`
  padding: 3rem 0;
  color: #fff;

  ${media.tablet`
    flex: 1;
    padding-left: 3rem;
    padding-right: 3rem;
  `}
`

const HorizontalCardDescription = styled.div`
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.8em;
  color: ${props => props.theme.colors.base};

  ${media.tablet`
    text-align: ${props => (props.reversed ? `left` : `right`)};
  `}
`

const StyledLineBreak = styled(LineBreak)`
  ${media.tablet`
    ${props =>
      props.reversed
        ? `margin-right: auto;
       margin-left: 0;
      `
        : `
        margin-left: auto;
        margin-right: 0;
      `}
  `}
`

export const HorizontalCard = props => (
  <HorizontalCardContainer reversed={props.reversed}>
    <CoverImgContainer>
      <CoverImg fluid={props.image.fluid} />
    </CoverImgContainer>
    <HorizontalCardBody>
      <HorizontalCardTitle reversed={props.reversed}>
        {props.name}
      </HorizontalCardTitle>
      <StyledLineBreak reversed={props.reversed} />
      <HorizontalCardDescription
        reversed={props.reversed}
        dangerouslySetInnerHTML={{
          __html: props.description.childMarkdownRemark.html,
        }}
      />
    </HorizontalCardBody>
  </HorizontalCardContainer>
)

HorizontalCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  image: PropTypes.object.isRequired,
  reversed: PropTypes.bool,
}

HorizontalCard.defaultProps = {
  description: '',
  reversed: false,
}
