import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'

import { media } from '../../utils/media'

const SubSection = styled.section`
  margin-bottom: 1.5rem;
  padding-right: 1rem;
  ${media.desktop`
    margin-bottom: 0;
    flex: 1;
  `}
`

const CardRoot = styled.div`
  position: relative;
  padding-top: 1rem;

  ${media.desktop`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: unset;
      padding: 2rem 0;
      grid-gap: 2rem;
      margin: auto;
    `}
`
const Title = styled.h5`
  font-weight: 500;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${media.desktop`
      font-size: 2rem;
      margin-bottom: 1rem;
      margin-top: 0;
    `}
`

const Description = styled.div`
  line-height: 1.8;
  margin-right: 1.25rem;

  ${media.desktop`
      font-size: 1rem;
      margin-right: 0;
      margin-top: 1rem;
    `}
`

const SubTitle = styled.h6`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.base}f4;
`

const NoInformationText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.colors.base}c4;
  grid-column: span 2;
  justify-self: center;
  font-style: italic;
`

const Pros = styled.div`
  ul {
    list-style: none;
    margin-left: 0;
  }
  li {
    display: flex;
    margin-left: 0.7rem;
    ::before {
      content: '+';
      margin-right: 0.7rem;
      font-weight: bold;
      position: relative;
      left: 0;
      color: green;
    }
  }
`

const Cons = styled.div`
  ul {
    list-style: none;
    margin-left: 0;
  }
  li {
    display: flex;
    margin-left: 0.7rem;
    ::before {
      content: '-';
      margin-right: 0.7rem;
      font-weight: bold;
      position: relative;
      left: 0;
      color: red;
    }
  }
`
  
export const FullCardInformation = ({ material }) => {
  const {
    usage,
    types,
    description,
    name,
    coverPhoto,
    sampleImages,
    pros,
    cons
  } = material

  const mainImage = sampleImages && sampleImages[0]

  return (
    <CardRoot>
      <div
        css={css`
          align-self: center;
          grid-column: span 3;
          display: flex;
          flex-direction: column;

          ${media.desktop`
            flex-direction: row;
          `}
        `}
      >
        {mainImage && (
          <CoverImg
            fluid={mainImage.fluid}
            alt={mainImage.title}
            fadeIn={false}
            css={css`
              margin-bottom: 1rem;
              margin-left: -1.25rem;

              ${media.desktop`
                margin-bottom: 0;
                margin-left: 0;
                flex: 1;
            `}
            `}
          />
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;

            ${media.desktop`
              padding: 0 2rem;
              justify-content: center;
              flex: 1;
          `}
          `}
        >
          <Title>{name}</Title>
          {description && (
            <Description
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </div>
      <SubSection>
        <SubTitle>Usage: </SubTitle>
        {usage ? (
          <ItemList>
            {usage.map((usage, index) => {
              return <li key={index}>{usage}</li>
            })}
          </ItemList>
        ) : (
          <NoInformationText>No usage provided.</NoInformationText>
        )}
      </SubSection>
      <SubSection>
        <SubTitle>Types: </SubTitle>
        {types ? (
          <ItemList>
            {types.map((type, index) => {
              return <li key={index}>{type}</li>
            })}
          </ItemList>
        ) : (
          <NoInformationText>No types provided.</NoInformationText>
        )}
      </SubSection>
      {coverPhoto && (
        <SubSection
          css={css`
            grid-row: span 2;
          `}
        >
          <CoverImg fluid={coverPhoto.fluid} alt={coverPhoto.title} />
        </SubSection>
      )}
      <SubSection
        css={css`
          grid-column: 1;
        `}
      >
        <SubTitle>Pros:</SubTitle>
        {pros ? (
          <Pros>
            {documentToReactComponents(pros && pros.json)}
          </Pros>
        ) : (
          <NoInformationText>No pros provided.</NoInformationText>
        )}
      </SubSection>
      <SubSection>
        <SubTitle>Cons:</SubTitle>
        {cons ? (
          <Cons>
            {documentToReactComponents(cons && cons.json)}
          </Cons>
        ) : (
          <NoInformationText>No cons provided.</NoInformationText>
        )}
      </SubSection>
    </CardRoot>
  )
}

const CoverImg = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  ${media.tablet`
    max-height: none;
  `}

  height: 100%;
  min-height: inherit;
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
`

const ItemList = styled.ul`
  margin-left: 2rem;
  color: ${props => props.theme.colors.base}f4;
`

const FrontCardRoot = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 300px;

  :focus {
    outline: 1px solid blue;
  }

  ${media.desktop`
      display: block;
    `}
`

const Content = styled.div`
  color: ${props => props.theme.colors.base};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1rem;
  width: 100%;
  min-height: 48px;
  padding: 1rem 1rem;
  position: absolute;
  z-index: 1;
  ${media.desktop`
      min-height: none;
    `}
`

const FrontTitle = styled.span`
  text-align: left;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0;
  color: ${props => props.theme.colors.white};
`

const FrontCard = props => {

  return (
    <FrontCardRoot onClick={props.onClick}>
      {props.image && (
        <CoverImg
          fluid={props.image.fluid}
          alt={props.image.title}
          fadeIn={false}
          css={css`
            border-radius: 10px;
            &::before {
              content: '';
              opacity: 0.4;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 100%;
              width: 100%;
              z-index: 1;
            }
          `}
        />
      )}
      <div
        css={css`
          border-radius: 10px;
          background-image: linear-gradient(
            -180deg,
            rgba(0, 0, 0, 0) 3%,
            rgb(0, 0, 0, 0.7) 100%
          ) !important;
          opacity: 0.6 !important;
          position: absolute !important;
          height: 60% !important;
          width: 100% !important;
          left: 0px !important;
          bottom: 0px !important;
        `}
      />
      <Content>
        <FrontTitle>{props.name}</FrontTitle>
      </Content>
    </FrontCardRoot>
  )
}

export const MaterialCard = props => {
  const { onClick, material} = props
  return (
    <div
      onClick={onClick}
      css={css`
        cursor: pointer;
        :focus {
          outline: none;
          > :first-child {
            z-index: 1;
            box-shadow: 0 0 0 3px ${props => props.theme.colors.primaryBrand};
          }
        }
      `}
    >
      <FrontCard
        name={material.name}
        image={material.coverPhoto}
        sampleImages={material.sampleImages}
        description={material.description}
        usage={material.usage}
        types={material.types}
      />
    </div>
  )
}

MaterialCard.propTypes = {
  name: PropTypes.string,
  featured: PropTypes.bool,
}

MaterialCard.defaultProps = {
  name: "ABS",
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  featured: false,
}

export const materialCardFragment = graphql`
  fragment MaterialCardFragment on ContentfulMaterial {
    name
    id
    category
    sampleImages {
      id
      title
      fixed(quality: 100, width: 120, height: 120) {
        ...GatsbyContentfulFixed_withWebp
      }
      thumbnail: fixed(quality: 100, width: 40, height: 40) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    pros {
      raw
    }
    cons {
      raw
    }
    usage
    types
    coverPhoto {
      fixed(quality: 100, height: 400) {
        ...GatsbyContentfulFixed_withWebp_noBase64
      }
      moreInfo: fluid(maxHeight: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
  }
`