import React, { Component } from "react";
import Slider from "react-slick";

export default class FadeCarousel extends Component {
  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 1500,
      ...this.props,
    };
  
    const { props } = this
    return (
      <div>
        <Slider {...settings}>
          {props.children}
        </Slider>
      </div>
    );
  }
}