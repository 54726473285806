import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Img from 'gatsby-image'

const ProjectCardContainer = styled.section `
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`
const ProjectCardImage = styled(Img)`
  height: 300px;
  width: 100%;
  background-color: #C47D7D;
`;

const ProjectCardTitle = styled.h5 `
  font-size: 24px;
  text-align: center;
  margin-bottom: 1rem;
`;

const ProjectCardBody = styled.div `
  background-color: #fff;
  padding: 3rem 1rem;
`;

const ProjectCardDescription = styled.p `
  font-size: 1em;
  text-align: center;
`;

export const ProjectCard = props => (
  <ProjectCardContainer>
    <ProjectCardImage fluid={props.image.fluid}/>
    <ProjectCardBody>
      <ProjectCardTitle>{props.title}</ProjectCardTitle>
      <ProjectCardDescription>{props.description}</ProjectCardDescription>
    </ProjectCardBody>
  </ProjectCardContainer>
)

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

ProjectCard.defaultProps = {
  description: '',
}