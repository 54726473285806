import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import { media } from '../../utils/media'

const IndustryRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  position: relative;
  color: ${props => props.theme.colors.white};
  border-radius: 10px;
  margin-bottom: 120px;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    width: inherit;
    position: relative;
    color: ${props => props.theme.colors.white};
    background-color: unset;
    margin-bottom: 0;
  `}
`
const IndustryName = styled.h3`
  color: ${props => props.theme.colors.white};
  font-size: 1.8rem;
  margin: 0 0 1rem 1rem;

  ${media.desktop`
    margin-bottom: 1rem;
    font-size: 2rem;
    margin-top: 0;
    margin-left: 0;
  `}
`
const IndustryDescription = styled.div`
  color: ${props => props.theme.colors.white}d4;
  line-height: 1.6;
  font-size: 16px;
  padding: 0 1rem;
  min-height: 300px;
  position: absolute;
  && {
    margin-bottom: 2rem;
    min-height: unset;
  }

  ${media.desktop`
    color: ${props => props.theme.colors.white}c4;
    padding: 0;
    font-size: 1rem;
    max-width: 450px;
    && {
      margin-bottom: 0;
    }
  `}
`

export const IndustryCard = ({ industry }) => {
  const { image, name, description } = industry
  return (
    <IndustryRoot>
      {image && (
        <div>
          <Img
            fluid={image.fluid}
            alt={image.title}
            css={css`
              height: 100%;
              ::before {
                content: '';
                background-image: linear-gradient(
                  -180deg,
                  rgba(0, 53, 107, 0.12) 0%,
                  #001839 100%
                );
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
              }
              ${media.desktop`
                border-radius: 0;
                &::before {
                  content: '';
                  background-image: linear-gradient(-180deg, rgba(0,53,107,0.12) 0%, #001839 100%);
                  position: absolute;
                  top: 2px;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  height: 100%;
                  width: 100%;
                  z-index: 1;
                }
              `}
            `}
          />
        </div>
      )}
      <div
        css={css`
          word-wrap: normal;
          transform: translateY(-100px);
          z-index: 2;
          ${media.desktop`
          position: relative;
          align-self: start;
          left: 25%;
          top: -100px;
          z-index: 2;
        `}
        `}
      >
        <IndustryName>{name}</IndustryName>
      </div>
      <div
        css={css`
          transform: translateY(-100px);
          z-index: 2;
          ${media.desktop`
            position: relative;
            left: 25%;
            top: -100px;
            z-index: 2;
          `}
        `}
      >
        <IndustryDescription
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      </div>
    </IndustryRoot>
  )
}

export const industryCardFragment = graphql `
  fragment IndustryCardFragment on ContentfulIndustry {
    id
    name
    image {
      title
      fluid(maxHeight: 600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
  }
`

IndustryCard.propTypes = {
  industry: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
}

IndustryCard.defaultProps = {
  description: '',
  image: null,
}