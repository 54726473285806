import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../../utils/media'

const CardContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 70%;
  padding: 2rem;

  ${media.desktop`
    padding: 2rem 2rem;
  `}
`

const CoverImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.base};
  ${media.tablet`
      max-height: none;
    `
  }

  ${media.desktop`
    min-height: unset;
  `}
  height: ${props => props.height};
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 3%, rgb(0, 0, 0) 100%) !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80%;
    width: 100%;
    z-index: 1;
  }
`

const PortfolioCardContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: auto;
`

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

const CardTitle = styled.h3`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
`

const CardExcerpt = styled.p`
  color: ${props => props.theme.colors.tertiary};
  margin-bottom: 0;
`
const Tag = styled.span`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.base};
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
`

const TopContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin-bottom: 2rem;
`

const Tags = ({ tags }) => (
  <div css={css`
    display: flex;
    flex-flow: wrap;
  `}>
    {tags.map((tag, index) => {
      return <Tag key={index}>{tag}</Tag>
    })}
  </div>
)
export const PortfolioCard = props => (
  <PortfolioCardContainer>
    <Wrapper>
      {props.portfolio.coverPhoto &&
        <CoverImg
          fluid={props.portfolio.coverPhoto.fluid}
          height={props.height || `100%`}
          alt={props.portfolio.coverPhoto.title}
        />}
      <CardContent>
        {
          props.portfolio.categories && 
          <TopContent>
            <Tags tags={props.portfolio.categories} />
          </TopContent>
        }
        <CardTitle>{props.portfolio.title}</CardTitle>
        <CardExcerpt>{props.portfolio.shortDescription}</CardExcerpt>
      </CardContent>
    </Wrapper>
  </PortfolioCardContainer>
)

export const PortfolioCardFragmentWithCategories = graphql `
  fragment PortfolioCardFragment_withCategories on ContentfulPortfolioWork {
    title
    id
    shortDescription
    categories
    coverPhoto {
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
  }
`

export const PortfolioCardFragment = graphql`
  fragment PortfolioCardFragment on ContentfulPortfolioWork {
    title
    id
    shortDescription
    coverPhoto {
      fluid(quality: 80 maxWidth: 120) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`

PortfolioCard.propTypes = {
  portfolio: PropTypes.shape({
    coverPhoto: PropTypes.shape({
      fluid: PropTypes.object.isRequired,
    }),
    categories: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
  }),
  height: PropTypes.string
}