import styled from 'styled-components'

export const FloatingCard = styled.div`
  background-color: ${props => props.dark ? props.theme.colors.lightBase : props.theme.colors.white};
  border-radius: 4px;
  transform: scale(1);
  min-height: ${props => props.small ? `200px` : `unset`};
  width: ${props => props.small ? `200px` : `unset`};
  display: flex;
  justify-content: center;

  :hover {
    transform: scale(1.02);
    transition-duration: 150ms;
    transition-property: transform;
  }
`
