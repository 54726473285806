import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CardTitle = styled.h2`
  margin-top: 1rem;
  text-align: center;
`

const StyledImg = styled(Img)`
  width: 300px;
  height: auto;
`

export const Card = props => (
  <Container>
    <StyledImg
      fluid={props.media.fluid}
      alt={props.media.title}
    />
    <CardTitle>{props.title}</CardTitle>
  </Container>
)

Card.propTypes = {
  media: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
  }),
  title: PropTypes.string.isRequired,
}

Card.defaultProps = {
  coverPhoto: {
    fixed: null,
    fluid: null,
  },
  title: 'Title is not given',
}