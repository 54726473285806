import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

import { media } from '../../utils/media'
import { space, fontSizes, mediaQueries } from '../../utils/tokens'

const StepCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: 100%;
  min-height: 300px;
  position: relative;
  margin-bottom: ${space[5]}px;

  ${mediaQueries.md} {
    margin-bottom: ${space[5]}px;
  }
`
const Number = styled.div`
  font-weight: bold;
  font-size: ${fontSizes[2]};
  margin-bottom: ${space[3]}px;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 42px;
  height: 42px;
  background-color: ${props => props.theme.colors.primaryBrand};
  border-radius: 100%;
`

const Title = styled.h5`
  font-weight: bold;
  font-size: ${fontSizes[3]};
  min-height: ${space[5]}px;
  padding: 0 ${space[3]}px;
  text-align: center;
  z-index: 1;

  ${mediaQueries.md} {
    margin-bottom: ${space[2]}px;
  }
`

const Description = styled.div`
  font-size: ${fontSizes[2]};
  font-weight: 500;
  line-height: 1.8;
  color: ${props => props.theme.colors.base};
  opacity: 0.8;
`

const Icon = styled.img`
  z-index: 1;
  width: 100%;
  margin: 6rem auto 1rem;
  position: relative;
  opacity: 1;

  ${media.desktop`
      margin: 0;
    `}
`

const StepCard = (props) => {
  const isEven = props.number % 2 === 0

  return (
    <StepCardRoot isEven={isEven}>
      <div>
        <Number>
          <span>{props.number}</span>
        </Number>
      </div>
      <Title>{props.title}</Title>
      <div
        css={css({
          marginBottom: `${space[3]}px`,
          width: `100%`,
        })}
      >
        {props.icon &&
          (!props.icon.fluid ? (
            <Icon
              src={props.icon.file.url}
              alt={props.icon.file.title}
              isRight={!isEven}
            />
          ) : (
            <div
              css={css`
                width: 100%;
              `}
            >
              <Img fluid={props.icon.fluid} alt={props.icon.title} />
            </div>
          ))}
      </div>
      <div>
        {props.description && (
          <Description
            dangerouslySetInnerHTML={{
              __html: props.description.childMarkdownRemark.html,
            }}
          />
        )}
      </div>
    </StepCardRoot>
  )
}

export default StepCard