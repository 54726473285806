import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import theme from '../../styles/theme'

import { media } from '../../utils/media'

const FloatingCard = styled.div`
  background-color: ${props => props.dark ? props.theme.colors.lightBase : props.theme.colors.white};
  border-radius: 4px;
  min-height: ${props => props.small ? `200px` : `200px`};
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: ${props => props.theme.dropShadow};
  position: relative;
  overflow: hidden;
  -webkit-overflow: hidden;

  transition-duration: 150ms;
  transition-property: background-color;

  ${props => {
    if(props.shape === 'hexagon') {
      return `clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);`
    }
  }}

  :hover {
    transition-duration: 300ms;
    transition-property: background-color;
    background-color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.lightBase};
    > * {
      color: ${props => props.dark ? props.theme.colors.base : props.theme.colors.white};
    }

    img {
      right: calc(-50% + 2rem);
      transition-duration: 600ms;
      transition-property: right;
    }
  }
`

const ServiceCardImg = styled.img`
  height: 100%;
  padding: 2rem 1rem;
  right: calc(-50%);
  transition-duration: 300ms;
  transition-property: right;
  position: absolute;
  pointer-events: none;
  z-index: 0;
`
export const FloatingServiceCard = props => {
  const { title } = props
  return (
    <FloatingCard
      dark={props.dark}
      small={props.small}
      shape={props.shape}
    >
      <ServiceCardImg src={props.icon} {...props} />
        <h4 css={css`
          font-size: 2.5vh;
          font-weight: bold;
          color: ${props.dark ? theme.colors.white : theme.colors.base};
          z-index: 1;
          margin-bottom: 0;
          text-align: center;
          padding: 8px;

          ${media.desktop`
            font-size: 1.5rem;
            padding: 0;
          `}
        `}>
          {title}
        </h4>
    </FloatingCard>
  )
}

FloatingServiceCard.propTypes = {
  icon: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.object,
  hasHover: PropTypes.bool,
  size: PropTypes.bool,
}

FloatingServiceCard.defaultProps = {
  dark: false,
  title: '3D Modeling',
  hasHover: false,
}