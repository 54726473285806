import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

import { media } from '../../utils/media'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`

const StyledImg = styled(Img)`
  border-radius: 10px;
  width: 100%;
  height: 100%;
`
const AlbumTitle = styled.h2`
  margin-left: 0;
  padding: 4px 1rem;
  text-align: left;
  position: absolute;
  color: white;
  z-index: 1;
  font-size: 1.25rem;
  font-weight: bold;
  bottom: 1rem;
  left: 0;
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin-bottom: 0;

  ${media.desktop`
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
  `}
`

export const AlbumCard = props => (
  <Container>
    <StyledImg  
      fluid={props.coverPhoto.fluid}
      alt={props.coverPhoto.title}
    />
    <div 
      css={css`
        border-radius: 10px;
        background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 3%, rgb(0, 0, 0) 100%) !important;
        opacity: 0.6 !important;
        position: absolute !important;
        height: 30% !important;
        width: 100% !important;
        left: 0px !important;
        bottom: 0px !important;`
      }
    />
    <AlbumTitle>{props.title}</AlbumTitle>
  </Container>
)

AlbumCard.propTypes = {
  coverPhoto: PropTypes.shape({
    fixed: PropTypes.object,
    fluid: PropTypes.object,
    title: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
}

AlbumCard.defaultProps = {
  coverPhoto: {
    fixed: null,
    fluid: null,
  },
  title: 'Title is not given',
}