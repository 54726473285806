import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { media } from '../../utils/media'
import { space, fontSizes, breakpoints, palette, mediaQueries, fontFamily, lineHeights} from '../../utils/tokens'

const CardContent = styled.div`
  width: 100%;
  padding: ${space[3]}px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 70%;

  ${mediaQueries.lg} {
    padding: 0 ${space[4]}px;
  }
`

const CoverImg = styled(Img)`
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${props => props.theme.colors.base};

  ${mediaQueries.lg} {
    position: absolute !important;
    top: 0;
    left: 0;
  }

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background-image: ${props => props.theme.gradients.primary};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

const BlogCardContainer = styled.article`
  width: 100%;
  max-width: ${breakpoints.lg};
  height: 100%;
  overflow: hidden;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`

const CardTitle = styled.span`
  font-size: ${fontSizes[3]};
  font-weight: bold;
  margin-bottom: ${space[3]}px;
`

const CardExcerpt = styled.p`
  line-height: ${lineHeights.body};
  font-size: 16px;
  font-family: ${fontFamily.body};
`
const Tag = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${fontSizes[1]};
  margin-right: 4px;
  margin-bottom: 4px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  background-color: ${props => props.theme.colors.base};
  padding: 4px 8px;
`

const Date = styled.span`
  color: ${palette.grey[70]};
`

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${space[3]}px;
  margin-top: ${space[1]}px;
  width: 100%;
`

const Tags = ({ tags }) => (
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${media.desktop`
      display: block;
    `}
  `}>
    {tags && tags.map((tag, index) => {
      return <Tag key={index}>{tag}</Tag>
    })}
  </div>
)
export const BlogCard = props => (
  <BlogCardContainer className={props.className}>
    <Wrapper>
      {props.image && (
        <Link
          to={props.url}
          css={css({
            position: `relative`,
            width: `100%`,
            display: `block`,
            [mediaQueries.lg]: {
              minWidth: `300px`,
              maxWidth: `300px`,
              minHeight: `200px`,
              maxHeight: `200px`,
            }
          })}
          css={props.imageContainerCss}
        >
          <CoverImg
            fluid={props.image.fluid} 
            alt={props.image.title}
          />
        </Link>
      )}
      <CardContent css={props.cardContentCss}>
        <TopContent>
          <Date>{props.date}</Date>
          <Tags tags={props.tags} />
        </TopContent>
        <Link
          to={props.url}
          css={css({
            display: `block`,
            marginBottom: `${space[2]}px`,
            textDecoration: `none`,
            '&:hover': {
              color: palette.orange[60],
            }
          })}
        >
          <CardTitle>{props.title}</CardTitle>
        </Link>
        <CardExcerpt css={props.excerptCss}>{props.excerpt}</CardExcerpt>
        <Link
          to={props.url}
          css={css({
            flex: 1,
            display: `flex`,
            alignItems: `flex-end`,
            color: palette.orange[70],
            textDecoration: `none`,
            fontWeight: `500`,
            marginBottom: `${space[1]}px`,
            marginTop: `${space[3]}px`,
            '&:hover': {
              textDecoration: `underline`,
              color: palette.orange[60],
            },
            [mediaQueries.lg]: {
              marginBottom: 0,
            }
          })}
        >Read More »</Link>
      </CardContent>
    </Wrapper>
  </BlogCardContainer>
)

export const blogCardFragment = graphql`
  fragment BlogCardFragment on ContentfulBlog {
    title
    tags
    id
    coverPhoto {
      title
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    article {
      childMarkdownRemark {
        excerpt
      }
    }
    fields {
      path
    }
  }
`

BlogCard.propTypes = {
  image: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
}