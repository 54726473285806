import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Scene = styled.div`
  width: 100%;
  height: 100%;
  perspective: 600px;
  transform-style: preserve-3d;
  margin: 0 auto;
  transition: transform 500ms cubic cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
`
const Card = styled.div`
  width: inherit;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;

  transform: ${props => props.isFlipped ? `rotateY(180deg)` : null};
`

const CardFace = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
`

const CardFaceFront = styled(CardFace)`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

`

const CardFaceBack = styled(CardFace)`
  transform: rotateY(180deg);

  ::after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    z-index: 1;
    pointer-events: none;
  }
`

export const CardFlip = props => {
  const [isFlipped, setFlip] = useState(true)

  const handleFlip = () => {
    setFlip(!isFlipped)
  }
  
  return (
    <Scene onClick={handleFlip} height={props.height}>
      <Card isFlipped={isFlipped}>
        <CardFaceFront>{props.front}</CardFaceFront>
        <CardFaceBack>{props.back}</CardFaceBack>
      </Card>
    </Scene>
  )
}

CardFlip.propTypes = {
  front: PropTypes.element.isRequired,
  back: PropTypes.element.isRequired,
  height: PropTypes.string,
}