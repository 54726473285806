import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const IconSvg = styled.img `
  width: 150px;
`
// thus allows anyone that uses this component to 
// either use an svg file or an image file
const CardImage = props => {
  const {contentType, url} = props.icon.file
  const { title } = props.icon
  if (contentType === "image/jpeg") {
    if (props.icon.fluid) {
      return <Img fluid={props.icon.fluid} className={props.className} alt={title}/>
    } else if (props.icon.fixed) {
      return <Img fixed={props.icon.fixed} className={props.className} alt={title}/>
    }
  } else if (contentType === "image/svg+xml") {
    return <IconSvg src={url} className={props.className} alt={title}/>
  }
}

CardImage.propTypes = {
  icon: PropTypes.shape({
    file: PropTypes.shape({contentType: PropTypes.string.isRequired, url: PropTypes.string.isRequired}),
    fluid: PropTypes.object,
    fixed: PropTypes.object
  }).isRequired
}

const HowItWorksCardContainer = styled.section`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StepNumber = styled.span`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 500;

`

const HowItWorksCardTitle = styled.h3`
  font-size: 2em;
  text-align: center;
  margin-bottom: 1rem;
`;

const HowItWorksCardBody = styled.div`
  padding: 2rem 1rem;
`;

const HowItWorksCardDescription = styled.div`
  &&& {
    font-size: 1.2em;
    text-align: left;
    font-weight: 400;
  }
`;

export const HowItWorksCard = props => {
  return(
    <HowItWorksCardContainer>
      <StepNumber>Step {props.stepNumber}:</StepNumber>
      <HowItWorksCardTitle>{props.title}</HowItWorksCardTitle>
      <CardImage icon={props.icon} />
      <HowItWorksCardBody>
        <HowItWorksCardDescription
          dangerouslySetInnerHTML = {{ __html: props.instructions.childMarkdownRemark.html}} 
        />
      </HowItWorksCardBody>
    </HowItWorksCardContainer>
  )
}

export const howItWorksFragment = graphql`
  fragment HowItWorksCardFragment on ContentfulHowItWorksStep {
    title
    id
    icon {
      fluid(quality: 100, maxHeight: 400, maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
      title
      file {
        url
        contentType
      }
    }
    instructions {
      childMarkdownRemark {
        html
      }
    }
  }
`

HowItWorksCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.shape({
    file: PropTypes.shape({
      contentType: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    fluid: PropTypes.object,
    fixed: PropTypes.object,
  }),
}

HowItWorksCard.defaultProps = {
  description: '',
  imageFixed: null,
  imageFluid: null,
  icon: null,
}