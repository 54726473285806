import React from 'react';
import styled, { css } from 'styled-components'
import { FaQuoteLeft, FaStar } from 'react-icons/fa'

import { FloatingCard } from './FloatingCard'

import { media } from '../../utils/media'
import { palette, space } from '../../utils/tokens';

const ReviewContent = styled.section`
  line-height: 1.6;
  font-size: 16px;
  ${media.desktop`
    padding: 0 2rem;
    font-size: 1.2rem;
  `}
`
const ReviewMessage = styled.p`
  text-align: left;
  margin-bottom: 1rem;
`

const ReviewAuthor = styled.p`
  text-align: right;
  width: inherit;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
`
export const ReviewCard = props => (
  <FloatingCard
    css={css`
      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      margin: 1rem;
      margin-left: auto;
      margin-right: 1rem;

      :hover {
        transform: none;
      }
    `}
  >
    <ReviewContent>
      <FaQuoteLeft
        css={css`
          font-size: 2rem;
          margin-bottom: ${space[2]}px;
          ${media.desktop`
            font-size: 3rem;
            margin-bottom: ${space[3]}px;
          `}
        `}
      />
      <ReviewMessage>{props.message}</ReviewMessage>
      <ReviewStars />
      <ReviewAuthor>- {props.author}</ReviewAuthor>
      <p
        css={css({
          textAlign: `right`,
        })}
      >
        Google Review
      </p>
    </ReviewContent>
  </FloatingCard>
)

  const ReviewStars = () => {
    return (
      <div
        css={css({
          display: `flex`,
          marginBottom: `${space[2]}px`,
          justifyContent: `flex-end`,
        })}
      >
        <FaStar
          css={css({
            color: palette.orange[40],
          })}
        />
        <FaStar
          css={css({
            color: palette.orange[40],
          })}
        />
        <FaStar
          css={css({
            color: palette.orange[40],
          })}
        />
        <FaStar
          css={css({
            color: palette.orange[40],
          })}
        />
        <FaStar
          css={css({
            color: palette.orange[40],
          })}
        />
      </div>
    )
  }